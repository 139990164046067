<template>
  <span>
    <a
      class="removeLink 00"
      @click="toRouter()"
      v-if="isMobile && renderComponent"
    >
      <div class="spaceCourcex" v-if="type === 'course'">
        <vue-aspect-ratio ar="16:9" width="100%" v-if="!homeVertical">
          <div :class="{ box: !isMobile, boxmobile: isMobile }">
            <div class="spaceRatingAdm" v-if="permission && ratingHome">
              <div class="rating">
                <img src="@/assets/icons/star.svg" />
                <div v-if="course.rating !== null">{{ course.rating }}</div>
                <div v-else>0</div>
              </div>
            </div>
            <is-available :has-access="course.has_access"/>
            <div
              :class="{ 'action-box': !isMobile, 'action-boxmobile': isMobile }"
              v-if="permission"
            >
              <router-link
                :to="{ name: 'curso', params: { curso: course.id } }"
                class="removeLink disableLink"
              >
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
              </router-link>
              <router-link
                v-if="permission && !isMobile"
                :to="{
                  name: 'config_curso_param',
                  params: { curso: course.id },
                }"
                class="removeLink disableLink"
              >
                <img src="@/assets/icons/edit.svg" />
                {{ $t('home2.course_template.editar') }}
              </router-link>
            </div>
            <div
              :class="{
                'action-box2': !isMobile,
                'action-box2mobile 444': isMobile,
              }"
              v-if="permission === false"
            >
              <router-link
                v-if="
                  course.has_access === true && course.is_liberated === true
                "
                :to="{ name: 'curso', params: { curso: course.id } }"
                class="removeLink disableLink"
              >
                <div style="width: 100%">
                  <div class="spaceRating" v-if="ratingHome">
                    <div class="rating">
                      <img src="@/assets/icons/star.svg" />
                      <div v-if="course.rating !== null">
                        {{ course.rating }}
                      </div>
                      <div v-else>0</div>
                    </div>
                  </div>
                  <!-- <div class="descriptionCourse" v-html="course.description"></div> -->
                </div>
              </router-link>
              <a
                v-else
                class="removeLink disableLink"
                @click="openModalBuy(course)"
              >
                <div style="width: 100%">
                  <div class="spaceRating" v-if="ratingHome">
                    <div class="rating">
                      <img src="@/assets/icons/star.svg" />
                      <div v-if="course.rating !== null">
                        {{ course.rating }}
                      </div>
                      <div v-else>0</div>
                    </div>
                  </div>
                  <!-- <div class="descriptionCourse" v-html="course.description"></div> -->
                </div>
              </a>
            </div>
            <div
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && course.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_horizontal +
                  ' 200w, ' +
                  course.cover.medium_horizontal +
                  ' 300w, ' +
                  course.cover.large_horizontal +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && course.cover.small_horizontal === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_vertical +
                  ' 200w, ' +
                  course.cover.medium_vertical +
                  ' 300w, ' +
                  course.cover.large_vertical +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
            </div>
          </div>
        </vue-aspect-ratio>
        <vue-aspect-ratio ar="5:7" width="100%" v-if="homeVertical">
          <div :class="{ box: !isMobile, boxmobile: isMobile }">
            <div class="spaceRatingAdm" v-if="permission && ratingHome">
              <div class="rating">
                <img src="@/assets/icons/star.svg" />
                <div v-if="course.rating !== null">{{ course.rating }}</div>
                <div v-else>0</div>
              </div>
            </div>
            <is-available :has-access="course.has_access"/>
            <div
              :class="{ 'action-box': !isMobile, 'action-boxmobile': isMobile }"
              v-if="permission"
            >
              <router-link
                v-if="
                  course.has_access === true && course.is_liberated === true
                "
                :to="{ name: 'curso', params: { curso: course.id } }"
                class="removeLink disableLink"
              >
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
              </router-link>
              <router-link
                v-if="
                  permission &&
                  !isMobile &&
                  course.has_access === true &&
                  course.is_liberated === true
                "
                :to="{
                  name: 'config_curso_param',
                  params: { curso: course.id },
                }"
                class="removeLink disableLink"
              >
                <img src="@/assets/icons/edit.svg" />
                {{ $t('home2.course_template.editar') }}
              </router-link>
            </div>
            <div
              :class="{
                'action-box2': !isMobile,
                'action-box2mobile 333': isMobile,
              }"
              v-if="permission === false"
            >
              <router-link
                v-if="
                  course.has_access === true && course.is_liberated === true
                "
                :to="{ name: 'curso', params: { curso: course.id } }"
                class="removeLink disableLink"
              >
                <div style="width: 100%">
                  <div class="spaceRating" v-if="ratingHome">
                    <div class="rating">
                      <img src="@/assets/icons/star.svg" />
                      <div v-if="course.rating !== null">
                        {{ course.rating }}
                      </div>
                      <div v-else>0</div>
                    </div>
                  </div>
                  <!-- <div class="descriptionCourse" v-html="course.description"></div> -->
                </div>
              </router-link>
              <a
                v-else
                class="removeLink disableLink 333"
                @click="openModalBuy(course)"
              >
                <div style="width: 100%">
                  <div class="spaceRating" v-if="ratingHome">
                    <div class="rating">
                      <img src="@/assets/icons/star.svg" />
                      <div v-if="course.rating !== null">
                        {{ course.rating }}
                      </div>
                      <div v-else>0</div>
                    </div>
                  </div>
                  <!-- <div class="descriptionCourse" v-html="course.description"></div> -->
                </div>
              </a>
            </div>
            <div
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && course.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_horizontal +
                  ' 200w, ' +
                  course.cover.medium_horizontal +
                  ' 300w, ' +
                  course.cover.large_horizontal +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && course.cover.small_horizontal === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_vertical +
                  ' 200w, ' +
                  course.cover.medium_vertical +
                  ' 300w, ' +
                  course.cover.large_vertical +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
            </div>
          </div>
        </vue-aspect-ratio>
        <div class="flexName">
          <p class="titleCourse">{{ course.title }}</p>
          <div class="avaliation">
            <div class="qtd-alunos" v-if="permission">
              {{ course.students_count }}
              {{ $t('home2.course_template.alunos') }}
            </div>
            <div class="qtd-alunos" v-if="permission === false">
              {{ course.porcentagem }} %
            </div>
          </div>
        </div>
        <!-- <b-progress v-if="permission === false" :value="course.porcentagem" :max="100" class="mb-3"></b-progress> -->
      </div>
      <div class="spaceCourcex" v-if="type === 'module' && !withoutCategory">
        <div
          :class="{ box: !isMobile, boxmobile: isMobile }"
          v-if="course.cover !== null && course.cover.card !== undefined"
          v-bind:style="{
            'background-image': 'url(' + course.course.cover.card + ')',
          }"
        >
          <div
            :class="{ 'action-box': !isMobile, 'action-boxmobile': isMobile }"
          >
            <router-link
              :to="'curso/' + course.course_id + '/modulo/' + course.id"
              class="removeLink disableLink"
            >
              <img src="@/assets/icons/view.svg" />
              {{ $t('home2.course_template.visualizar') }}
            </router-link>
            <router-link
              v-if="permission && !isMobile"
              :to="'config_curso/' + course.course_id + '/module'"
              class="removeLink disableLink"
            >
              <img src="@/assets/icons/edit.svg" />
              {{ $t('home2.course_template.editar') }}
            </router-link>
          </div>
        </div>
        <div
          :class="{ box: !isMobile, boxmobile: isMobile }"
          v-else
          v-bind:style="{
            'background-image': 'url(' + course.course.cover.cdn_url + ')',
          }"
        >
          <div
            :class="{ 'action-box': !isMobile, 'action-boxmobile': isMobile }"
          >
            <router-link
              :to="'curso/' + course.course_id + '/modulo/' + course.id"
              class="removeLink disableLink"
            >
              <img src="@/assets/icons/view.svg" />
              {{ $t('home2.course_template.visualizar') }}
            </router-link>
            <router-link
              v-if="permission && !isMobile"
              :to="'config_curso/' + course.course_id + '/module'"
              class="removeLink disableLink"
            >
              <img src="@/assets/icons/edit.svg" />
              {{ $t('home2.course_template.editar') }}
            </router-link>
          </div>
        </div>
        <div class="flexName">
          <p class="titleCourse">{{ course.title }}</p>
        </div>
      </div>
      <div class="spaceCourcex" v-if="type === 'module' && withoutCategory">
        <vue-aspect-ratio ar="16:9" width="100%" v-if="!homeVertical">
          <div :class="{ box: !isMobile, boxmobile: isMobile }">
            <is-available :has-access="course.has_access"/>
            <div
              :class="{ 'action-box': !isMobile, 'action-boxmobile': isMobile }"
            >
              <router-link
                v-if="course.is_liberated"
                :to="'curso/' + course.course_id + '/modulo/' + course.id"
                class="removeLink disableLink"
              >
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
              </router-link>
              <router-link
                v-if="permission && !isMobile"
                :to="'config_curso/' + course.course_id + '/module'"
                class="removeLink disableLink"
              >
                <img src="@/assets/icons/edit.svg" />
                {{ $t('home2.course_template.editar') }}
              </router-link>
            </div>
            <div v-if="!course.is_liberated && !permission" class="flex">
              <div class="dataLiberacaoModule">
                {{ $t('home2.course_template.liberated') }}:
                {{ course.liberated_at | moment('DD/MM/YYYY') }}
              </div>
              <img
                src="@/assets/icons/block-lesson.svg"
                class="blockModeModule"
              />
            </div>
            <div
              v-if="course.cover !== null"
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && course.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_horizontal +
                  ' 200w, ' +
                  course.cover.medium_horizontal +
                  ' 300w, ' +
                  course.cover.large_horizontal +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && course.cover.small_horizontal === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_vertical +
                  ' 200w, ' +
                  course.cover.medium_vertical +
                  ' 300w, ' +
                  course.cover.large_vertical +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
            </div>
            <div
              v-else
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && AllHome.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  AllHome.cover.small_horizontal +
                  ' 200w, ' +
                  AllHome.cover.medium_horizontal +
                  ' 300w, ' +
                  AllHome.cover.large_horizontal +
                  ' 1000w'
                "
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && AllHome.cover.small_horizontal === null"
                class="testeimg"
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && AllHome.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  AllHome.cover.small_vertical +
                  ' 200w, ' +
                  AllHome.cover.medium_vertical +
                  ' 300w, ' +
                  AllHome.cover.large_vertical +
                  ' 1000w'
                "
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && AllHome.cover.small_vertical === null"
                class="testeimg"
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
            </div>
          </div>
        </vue-aspect-ratio>
        <vue-aspect-ratio ar="5:7" width="100%" v-if="homeVertical">
          <div :class="{ box: !isMobile, boxmobile: isMobile }">
            <is-available :has-access="course.has_access"/>
            <div
              :class="{ 'action-box': !isMobile, 'action-boxmobile': isMobile }"
            >
              <router-link
                v-if="course.is_liberated"
                :to="'curso/' + course.course_id + '/modulo/' + course.id"
                class="removeLink disableLink"
              >
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
              </router-link>
              <router-link
                v-if="permission && !isMobile"
                :to="'config_curso/' + course.course_id + '/module'"
                class="removeLink disableLink"
              >
                <img src="@/assets/icons/edit.svg" />
                {{ $t('home2.course_template.editar') }}
              </router-link>
            </div>
            <div v-if="!course.is_liberated" class="flex">
              <div class="dataLiberacaoModule">
                {{ $t('home2.course_template.liberated') }}:
                {{ course.liberated_at | moment('DD/MM/YYYY') }}
              </div>
              <img
                src="@/assets/icons/block-lesson.svg"
                class="blockModeModule"
              />
            </div>
            <div
              v-if="course.cover !== null"
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && course.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_horizontal +
                  ' 200w, ' +
                  course.cover.medium_horizontal +
                  ' 300w, ' +
                  course.cover.large_horizontal +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && course.cover.small_horizontal === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_vertical +
                  ' 200w, ' +
                  course.cover.medium_vertical +
                  ' 300w, ' +
                  course.cover.large_vertical +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
            </div>
            <div
              v-else
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && AllHome.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  AllHome.cover.small_horizontal +
                  ' 200w, ' +
                  AllHome.cover.medium_horizontal +
                  ' 300w, ' +
                  AllHome.cover.large_horizontal +
                  ' 1000w'
                "
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && AllHome.cover.small_horizontal === null"
                class="testeimg"
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && AllHome.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  AllHome.cover.small_vertical +
                  ' 200w, ' +
                  AllHome.cover.medium_vertical +
                  ' 300w, ' +
                  AllHome.cover.large_vertical +
                  ' 1000w'
                "
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && AllHome.cover.small_vertical === null"
                class="testeimg"
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
            </div>
          </div>
        </vue-aspect-ratio>
        <div class="flexName">
          <p class="titleCourse">{{ course.title }}</p>
        </div>
      </div>

      <div class="spaceCourcex" v-if="type === 'lesson'">
        <div
          :class="{ box: !isMobile, boxmobile: isMobile }"
          v-if="course.cover.card !== null"
          v-bind:style="{
            'background-image': 'url(' + course.course.cover.card + ')',
          }"
        >
          <div
            :class="{ 'action-box': !isMobile, 'action-boxmobile': isMobile }"
          >
            <router-link
              :to="
                'cursos/' +
                course.course_id +
                '/' +
                course.module_id +
                '/' +
                course.id
              "
              class="removeLink disableLink"
            >
              <img src="@/assets/icons/view.svg" />
              {{ $t('home2.course_template.visualizar') }}
            </router-link>
            <router-link
              v-if="permission && !isMobile"
              :to="
                'config_curso/' +
                course.course_id +
                '/module/' +
                course.module_id +
                'lesson'
              "
              class="removeLink disableLink"
            >
              <img src="@/assets/icons/edit.svg" />
              {{ $t('home2.course_template.editar') }}
            </router-link>
          </div>
        </div>
        <div
          :class="{ box: !isMobile, boxmobile: isMobile }"
          v-else
          v-bind:style="{
            'background-image': 'url(' + course.course.cover.cdn_url + ')',
          }"
        >
          <div
            :class="{ 'action-box': !isMobile, 'action-boxmobile': isMobile }"
          >
            <router-link
              :to="
                'cursos/' +
                course.course_id +
                '/' +
                course.module_id +
                '/' +
                course.id
              "
              class="removeLink disableLink"
            >
              <img src="@/assets/icons/view.svg" />
              {{ $t('home2.course_template.visualizar') }}
            </router-link>
            <router-link
              v-if="permission && !isMobile"
              :to="
                'config_curso/' +
                course.course_id +
                '/module/' +
                course.module_id +
                'lesson'
              "
              class="removeLink disableLink"
            >
              <img src="@/assets/icons/edit.svg" />
              {{ $t('home2.course_template.editar') }}
            </router-link>
          </div>
        </div>
        <div class="flexName">
          <p class="titleCourse">{{ course.title }}</p>
        </div>
      </div>
    </a>
    <span v-if="!isMobile">
      <div class="spaceCourcex" v-if="type === 'course' && renderComponent">
        <vue-aspect-ratio ar="5:7" width="100%" v-if="homeVertical === true">
          <div :class="{ box: !isMobile, boxmobile: isMobile }">
            <div class="spaceRatingAdm" v-if="ratingHome">
              <div class="rating">
                <img src="@/assets/icons/star.svg" />
                <div v-if="course.rating !== null">{{ course.rating }}</div>
                <div v-else>0</div>
              </div>
            </div>
            <is-available :has-access="course.has_access"/>
            <div
              :class="{
                'action-box': !isMobile,
                'action-boxmobile': isMobile,
                toVertical: homeVertical,
              }"
              v-if="permission"
            >
              <router-link
                :to="{ name: 'curso', params: { curso: course.id } }"
                class="removeLink"
              >
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
              </router-link>
              <router-link
                v-if="permission && !isMobile"
                :to="{
                  name: 'config_curso_param',
                  params: { curso: course.id },
                }"
                class="removeLink"
              >
                <img src="@/assets/icons/edit.svg" />
                {{ $t('home2.course_template.editar') }}
              </router-link>
            </div>
            <div
              :class="{
                'action-box': !isMobile,
                'action-box2mobile 111': isMobile,
              }"
              v-if="permission === false"
            >
              <router-link
                v-if="
                  course.has_access === true && course.is_liberated === true
                "
                :to="{ name: 'curso', params: { curso: course.id } }"
                class="removeLink"
              >
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
                <!-- <div style="width:100%">
                            <div class="spaceRating" v-if="ratingHome">
                            <div class="rating">
                                <img src="@/assets/icons/star.svg" />
                                <div v-if="course.rating !== null">{{ course.rating }}</div>
                                <div v-else>0</div>
                            </div>
                            </div>
                        </div> -->
              </router-link>
              <a v-else class="removeLink" @click="openModalBuy(course)">
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
                <!-- <div style="width:100%">
                            <div class="spaceRating" v-if="ratingHome">
                            <div class="rating">
                                <img src="@/assets/icons/star.svg" />
                                <div v-if="course.rating !== null">{{ course.rating }}</div>
                                <div v-else>0</div>
                            </div>
                            </div>
                        </div> -->
              </a>
            </div>
            <div
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && course.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_horizontal +
                  ' 200w, ' +
                  course.cover.medium_horizontal +
                  ' 300w, ' +
                  course.cover.large_horizontal +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && course.cover.small_horizontal === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_vertical +
                  ' 200w, ' +
                  course.cover.medium_vertical +
                  ' 300w, ' +
                  course.cover.large_vertical +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <!-- <img class="testeimg" :src="course.cover.medium_horizontal" alt="img" loading="lazy"> -->
            </div>
          </div>
        </vue-aspect-ratio>
        <vue-aspect-ratio ar="16:9" width="100%" v-if="homeVertical === false">
          <div :class="{ box: !isMobile, boxmobile: isMobile }">
            <div class="spaceRatingAdm" v-if="ratingHome">
              <div class="rating">
                <img src="@/assets/icons/star.svg" />
                <div v-if="course.rating !== null">{{ course.rating }}</div>
                <div v-else>0</div>
              </div>
            </div>
            <is-available :has-access="course.has_access"/>
            <div
              :class="{
                'action-box': !isMobile,
                'action-boxmobile': isMobile,
                toVertical: homeVertical,
              }"
              v-if="permission"
            >
              <router-link
                :to="{ name: 'curso', params: { curso: course.id } }"
                class="removeLink"
              >
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
              </router-link>
              <router-link
                v-if="permission && !isMobile"
                :to="{
                  name: 'config_curso_param',
                  params: { curso: course.id },
                }"
                class="removeLink"
              >
                <img src="@/assets/icons/edit.svg" />
                {{ $t('home2.course_template.editar') }}
              </router-link>
            </div>
            <div
              :class="{
                'action-box': !isMobile,
                'action-box2mobile 222': isMobile,
              }"
              v-if="permission === false"
            >
              <router-link
                v-if="
                  course.has_access === true && course.is_liberated === true
                "
                :to="{ name: 'curso', params: { curso: course.id } }"
                class="removeLink"
              >
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
                <!-- <div style="width:100%">
                            <div class="spaceRating" v-if="ratingHome">
                            <div class="rating">
                                <img src="@/assets/icons/star.svg" />
                                <div v-if="course.rating !== null">{{ course.rating }}</div>
                                <div v-else>0</div>
                            </div>
                            </div>
                        </div> -->
              </router-link>
              <a v-else class="removeLink" @click="openModalBuy(course)">
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
                <!-- <div style="width:100%">
                            <div class="spaceRating" v-if="ratingHome">
                            <div class="rating">
                                <img src="@/assets/icons/star.svg" />
                                <div v-if="course.rating !== null">{{ course.rating }}</div>
                                <div v-else>0</div>
                            </div>
                            </div>
                        </div> -->
              </a>
            </div>
            <div
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && course.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_horizontal +
                  ' 200w, ' +
                  course.cover.medium_horizontal +
                  ' 300w, ' +
                  course.cover.large_horizontal +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && course.cover.small_horizontal === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_vertical +
                  ' 200w, ' +
                  course.cover.medium_vertical +
                  ' 300w, ' +
                  course.cover.large_vertical +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />

              <!-- <img class="testeimg" :src="course.cover.medium_horizontal" alt="img" loading="lazy"> -->
            </div>
          </div>
        </vue-aspect-ratio>
        <div class="flexName">
          <p class="titleCourse">{{ course.title }}</p>
          <div class="avaliation">
            <div class="qtd-alunos" v-if="permission">
              {{ course.students_count }} alunos
            </div>
            <div class="qtd-alunos" v-if="permission === false">
              {{ course.porcentagem }} %
            </div>
          </div>
        </div>
        <!-- <b-progress v-if="permission === false" :value="course.porcentagem" :max="100" class="mb-3"></b-progress> -->
      </div>
      <div class="spaceCourcex" v-if="type === 'module' && renderComponent">
        <vue-aspect-ratio ar="16:9" width="100%" v-if="!homeVertical">
          <div :class="{ box: !isMobile, boxmobile: isMobile }">
            <is-available :has-access="course.has_access"/>
            <div
              :class="{ 'action-box': !isMobile, 'action-boxmobile': isMobile }"
            >
              <router-link
                v-if="
                  course.has_access === true && course.is_liberated === true
                "
                :to="'curso/' + course.course_id + '/modulo/' + course.id"
                class="removeLink"
              >
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
              </router-link>
              <a v-else @click="openModalBuy(course, true)" class="removeLink">
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
              </a>
              <router-link
                v-if="permission && !isMobile"
                :to="'config_curso/' + course.course_id + '/module'"
                class="removeLink"
              >
                <img src="@/assets/icons/edit.svg" />
                {{ $t('home2.course_template.editar') }}
              </router-link>
            </div>
            <div v-if="!course.is_liberated && !permission" class="flex">
              <div class="dataLiberacaoModule">
                {{ $t('home2.course_template.liberated') }}:
                {{ course.liberated_at | moment('DD/MM/YYYY') }}
              </div>
              <img
                src="@/assets/icons/block-lesson.svg"
                class="blockModeModule"
              />
            </div>
            <div
              v-if="course.cover !== null"
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && course.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_horizontal +
                  ' 200w, ' +
                  course.cover.medium_horizontal +
                  ' 300w, ' +
                  course.cover.large_horizontal +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && course.cover.small_horizontal === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_vertical +
                  ' 200w, ' +
                  course.cover.medium_vertical +
                  ' 300w, ' +
                  course.cover.large_vertical +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
            </div>
            <div
              v-else
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && AllHome.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  AllHome.cover.small_horizontal +
                  ' 200w, ' +
                  AllHome.cover.medium_horizontal +
                  ' 300w, ' +
                  AllHome.cover.large_horizontal +
                  ' 1000w'
                "
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && AllHome.cover.small_horizontal === null"
                class="testeimg"
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && AllHome.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  AllHome.cover.small_vertical +
                  ' 200w, ' +
                  AllHome.cover.medium_vertical +
                  ' 300w, ' +
                  AllHome.cover.large_vertical +
                  ' 1000w'
                "
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && AllHome.cover.small_vertical === null"
                class="testeimg"
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
            </div>
          </div>
        </vue-aspect-ratio>
        <vue-aspect-ratio ar="5:7" width="100%" v-if="homeVertical">
          <div :class="{ box: !isMobile, boxmobile: isMobile }">
            <is-available :has-access="course.has_access"/>
            <div
              :class="{ 'action-box': !isMobile, 'action-boxmobile': isMobile }"
            >
              <router-link
                v-if="
                  course.has_access === true && course.is_liberated === true
                "
                :to="'curso/' + course.course_id + '/modulo/' + course.id"
                class="removeLink"
              >
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
              </router-link>
              <a v-else @click="openModalBuy(course, true)" class="removeLink">
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
              </a>
              <router-link
                v-if="permission && !isMobile"
                :to="'config_curso/' + course.course_id + '/module'"
                class="removeLink"
              >
                <img src="@/assets/icons/edit.svg" />
                {{ $t('home2.course_template.editar') }}
              </router-link>
            </div>
            <div v-if="!course.is_liberated" class="flex">
              <div class="dataLiberacaoModule">
                {{ $t('home2.course_template.liberated') }}:
                {{ course.liberated_at | moment('DD/MM/YYYY') }}
              </div>
              <img
                src="@/assets/icons/block-lesson.svg"
                class="blockModeModule"
              />
            </div>
            <div
              v-if="course.cover !== null"
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && course.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_horizontal +
                  ' 200w, ' +
                  course.cover.medium_horizontal +
                  ' 300w, ' +
                  course.cover.large_horizontal +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && course.cover.small_horizontal === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_vertical +
                  ' 200w, ' +
                  course.cover.medium_vertical +
                  ' 300w, ' +
                  course.cover.large_vertical +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
            </div>
            <div
              v-else
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && AllHome.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  AllHome.cover.small_horizontal +
                  ' 200w, ' +
                  AllHome.cover.medium_horizontal +
                  ' 300w, ' +
                  AllHome.cover.large_horizontal +
                  ' 1000w'
                "
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && AllHome.cover.small_horizontal === null"
                class="testeimg"
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && AllHome.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  AllHome.cover.small_vertical +
                  ' 200w, ' +
                  AllHome.cover.medium_vertical +
                  ' 300w, ' +
                  AllHome.cover.large_vertical +
                  ' 1000w'
                "
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && AllHome.cover.small_vertical === null"
                class="testeimg"
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
            </div>
          </div>
        </vue-aspect-ratio>
        <div class="flexName">
          <p class="titleCourse">{{ course.title }}</p>
        </div>
      </div>
      <div class="spaceCourcex" v-if="type === 'lesson' && renderComponent">
        <div
          :class="{
            box: !isMobile,
            boxmobile: isMobile,
            toVertical: homeVertical,
          }"
          v-if="course.cover.card !== null"
          v-bind:style="{
            'background-image': 'url(' + course.course.cover.card + ')',
          }"
        >
          <div
            :class="{
              'action-box': !isMobile,
              'action-boxmobile': isMobile,
              toVertical: homeVertical,
            }"
          >
            <router-link
              :to="
                'cursos/' +
                course.course_id +
                '/' +
                course.module_id +
                '/' +
                course.id
              "
              class="removeLink"
            >
              <img src="@/assets/icons/view.svg" />
              {{ $t('home2.course_template.visualizar') }}
            </router-link>
            <router-link
              v-if="permission && !isMobile"
              :to="
                'config_curso/' +
                course.course_id +
                '/module/' +
                course.module_id +
                'lesson'
              "
              class="removeLink"
            >
              <img src="@/assets/icons/edit.svg" />
              {{ $t('home2.course_template.editar') }}
            </router-link>
          </div>
        </div>
        <div
          :class="{
            box: !isMobile,
            boxmobile: isMobile,
            toVertical: homeVertical,
          }"
          v-else
          v-bind:style="{
            'background-image': 'url(' + course.course.cover.cdn_url + ')',
          }"
        >
          <div
            :class="{
              'action-box': !isMobile,
              'action-boxmobile': isMobile,
              toVertical: homeVertical,
            }"
          >
            <router-link
              :to="
                'cursos/' +
                course.course_id +
                '/' +
                course.module_id +
                '/' +
                course.id
              "
              class="removeLink"
            >
              <img src="@/assets/icons/view.svg" />
              {{ $t('home2.course_template.visualizar') }}
            </router-link>
            <router-link
              v-if="permission && !isMobile"
              :to="
                'config_curso/' +
                course.course_id +
                '/module/' +
                course.module_id +
                'lesson'
              "
              class="removeLink"
            >
              <img src="@/assets/icons/edit.svg" />
              {{ $t('home2.course_template.editar') }}
            </router-link>
          </div>
        </div>
        <div class="flexName">
          <p class="titleCourse">{{ course.title }}</p>
        </div>
      </div>
    </span>
    <!-- <span v-if="isMobile">
      <div class="spaceCourcex" v-if="type === 'module'">
        <vue-aspect-ratio ar="16:9" width="100%" v-if="!homeVertical">
          <div :class="{ box: !isMobile, boxmobile: isMobile }">
            <div
              :class="{ 'action-box': !isMobile, 'action-boxmobile': isMobile }"
            >
              <router-link
                v-if="course.is_liberated"
                :to="'curso/' + course.course_id + '/modulo/' + course.id"
                class="removeLink"
              >
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
              </router-link>
              <router-link
                v-if="permission && !isMobile"
                :to="'config_curso/' + course.course_id + '/module'"
                class="removeLink"
              >
                <img src="@/assets/icons/edit.svg" />
                {{ $t('home2.course_template.editar') }}
              </router-link>
            </div>
            <div v-if="!course.is_liberated && !permission" class="flex">
              <div class="dataLiberacaoModule">
                {{ $t('home2.course_template.liberated') }}: {{ course.liberated_at | moment("DD/MM/YYYY") }}
              </div>
              <img
                src="@/assets/icons/block-lesson.svg"
                class="blockModeModule"
              />
            </div>
            <div
              v-if="course.cover !== null"
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && course.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_horizontal +
                  ' 200w, ' +
                  course.cover.medium_horizontal +
                  ' 300w, ' +
                  course.cover.large_horizontal +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && course.cover.small_horizontal === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_vertical +
                  ' 200w, ' +
                  course.cover.medium_vertical +
                  ' 300w, ' +
                  course.cover.large_vertical +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
            </div>
            <div
              v-else
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && AllHome.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  AllHome.cover.small_horizontal +
                  ' 200w, ' +
                  AllHome.cover.medium_horizontal +
                  ' 300w, ' +
                  AllHome.cover.large_horizontal +
                  ' 1000w'
                "
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && AllHome.cover.small_horizontal === null"
                class="testeimg"
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && AllHome.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  AllHome.cover.small_vertical +
                  ' 200w, ' +
                  AllHome.cover.medium_vertical +
                  ' 300w, ' +
                  AllHome.cover.large_vertical +
                  ' 1000w'
                "
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && AllHome.cover.small_vertical === null"
                class="testeimg"
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
            </div>
          </div>
        </vue-aspect-ratio>
        <vue-aspect-ratio ar="5:7" width="100%" v-if="homeVertical">
          <div :class="{ box: !isMobile, boxmobile: isMobile }">
            <div
              :class="{ 'action-box': !isMobile, 'action-boxmobile': isMobile }"
            >
              <router-link
                v-if="course.is_liberated"
                :to="'curso/' + course.course_id + '/modulo/' + course.id"
                class="removeLink"
              >
                <img src="@/assets/icons/view.svg" />
                {{ $t('home2.course_template.visualizar') }}
              </router-link>
              <router-link
                v-if="permission && !isMobile"
                :to="'config_curso/' + course.course_id + '/module'"
                class="removeLink"
              >
                <img src="@/assets/icons/edit.svg" />
                {{ $t('home2.course_template.editar') }}
              </router-link>
            </div>
            <div v-if="!course.is_liberated" class="flex">
              <div class="dataLiberacaoModule">
                {{ $t('home2.course_template.liberated') }}: {{ course.liberated_at | moment("DD/MM/YYYY") }}
              </div>
              <img
                src="@/assets/icons/block-lesson.svg"
                class="blockModeModule"
              />
            </div>
            <div
              v-if="course.cover !== null"
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && course.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_horizontal +
                  ' 200w, ' +
                  course.cover.medium_horizontal +
                  ' 300w, ' +
                  course.cover.large_horizontal +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && course.cover.small_horizontal === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  course.cover.small_vertical +
                  ' 200w, ' +
                  course.cover.medium_vertical +
                  ' 300w, ' +
                  course.cover.large_vertical +
                  ' 1000w'
                "
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && course.cover.small_vertical === null"
                class="testeimg"
                :src="course.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
            </div>
            <div
              v-else
              :class="{
                toGrayDesactive: course.status === 'draft',
                classtesteimg: !isMobile,
                classtesteimgmobile: isMobile,
              }"
            >
              <img
                v-if="!homeVertical && AllHome.cover.small_horizontal !== null"
                class="testeimg"
                :srcset="
                  AllHome.cover.small_horizontal +
                  ' 200w, ' +
                  AllHome.cover.medium_horizontal +
                  ' 300w, ' +
                  AllHome.cover.large_horizontal +
                  ' 1000w'
                "
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="!homeVertical && AllHome.cover.small_horizontal === null"
                class="testeimg"
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && AllHome.cover.small_vertical !== null"
                class="testeimg"
                :srcset="
                  AllHome.cover.small_vertical +
                  ' 200w, ' +
                  AllHome.cover.medium_vertical +
                  ' 300w, ' +
                  AllHome.cover.large_vertical +
                  ' 1000w'
                "
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
              <img
                v-if="homeVertical && AllHome.cover.small_vertical === null"
                class="testeimg"
                :src="AllHome.cover.cdn_url"
                alt="img"
                loading="lazy"
              />
            </div>
          </div>
        </vue-aspect-ratio>
        <div class="flexName">
          <p class="titleCourse">{{ course.title }}</p>
        </div>
      </div>
    </span> -->
  </span>
</template>

<script>
// Components
import VueAspectRatio from 'vue-aspect-ratio';
import IsAvailable from "./IsAvailable.vue"

import notify from '@/services/libs/notificacao';

export default {
  name: 'CourseHome',
  props: [
    'AllHome',
    'course',
    'permission',
    'ratingHome',
    'withoutCategory',
    'type',
    'isMobile',
    'listType',
  ],
  components: {
    'vue-aspect-ratio': VueAspectRatio,
    IsAvailable
  },
  data() {
    return {
      homeVertical: false,
      renderComponent: true,
    };
  },
  mounted() {
    this.homeVertical = this.listType;
    this.forceRerender();
  },
  watch: {
    listType() {
      this.homeVertical = this.listType;
      this.forceRerender();
    },
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    toRouter() {
      console.log(this.course);

      if (this.course.has_access === false) {
        if (this.course.course_id) {
          this.openModalBuy(this.course, true);
        } else {
          this.openModalBuy(this.course);
        }
        return;
      }

      if (
        this.course.has_access === true &&
        this.course.is_liberated === true &&
        !this.course.course_id
      ) {
        this.$router.push(`/curso/${this.course.id}`);
        return;
      } else if (this.course.is_liberated === true && this.course.course_id) {
        this.$router.push(
          `/curso/${this.course.course_id}/modulo/${this.course.id}`
        );
        return;
      } else if (this.permission) {
        this.$router.push(`/curso/${this.course.id}`);
        return;
      }
      return;
    },
    openModalBuy(data, module) {
      //console.log("dados do curso", data);
      if (data.is_liberated === false) {
        notify('erro', this.$t('home2.course_template.error.liberation'));
      } else {
        notify('erro', this.$t('home2.course_template.error.access'));
        if (module) {
          this.$root.$emit('modal-buy-course', data.curso);
        } else {
          this.$root.$emit('modal-buy-course', data);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.toGrayDesactive {
  filter: grayscale(100%);
}

.box {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin-right: 5px;
  background: #fff0;
  margin-bottom: 15px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.boxmobile {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin-right: 5px;
  background: #fff0;
  margin-bottom: 15px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.box img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.flexName {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .titleCourse {
    font-size: 16px;
    font-weight: 600;
    color: var(--fontcolor);
  }
  .qtd-alunos {
    font-size: 13px;
    color: #81858e;
    font-weight: 600;
    white-space: nowrap;
  }
}
.spaceHome {
  width: 100%;
  padding: 0 44px 0 48px;
  padding-top: 70px;
}
.spaceHomeMobile {
  width: 100%;
  padding-top: 35px;
}

// cursos
.listagem-cursos {
  padding: 45px 0;
}
.box-click {
  cursor: pointer;
}

.disableLink {
  pointer-events: none;
}

.action-box {
  width: 100%;
  height: 100%;
  background: rgba(0, 2, 23, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.3s;
  z-index: 1;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.action-boxmobile {
  width: 100%;
  height: 100%;
  background: rgba(0, 2, 23, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.3s;
  z-index: 1;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.action-box2 {
  width: 100%;
  height: 100%;
  background: rgba(0, 2, 23, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.3s;
  z-index: 1;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.action-box2mobile {
  width: 100%;
  height: 100%;
  background: rgba(0, 2, 23, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.3s;
  z-index: 1;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.box:hover .action-box {
  opacity: 1;
}

.classtesteimg:hover .action-box {
  opacity: 1;
}

.box:hover .action-box2 {
  opacity: 1;
}

.action-box2 a {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
}

.action-box2 a img {
  margin-right: 10px;
}

.action-box a:first-child {
  margin-right: 30px;
}
.action-box a {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}
.action-box a img {
  margin-right: 10px;
}

.action-box2mobile a {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
}

.action-box2mobile a img {
  margin-right: 10px;
}

.action-boxmobile a:first-child {
  margin-right: 30px;
}
.action-boxmobile a {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}
.action-boxmobile a img {
  margin-right: 10px;
}

.spaceRatingAdm {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  padding-right: 20px;
  padding-top: 20px;
  z-index: 9;
  .rating {
    width: 63.68px;
    height: 25px;
    background: rgba(255, 203, 55, 0.1);
    border: 1px solid #ffcb37;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    img {
      width: 10px;
      height: 8px;
    }
    div {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #ffcb37;
    }
  }
}

.spaceRating {
  width: 100%;
  height: 8vw;
  display: flex;
  justify-content: flex-end;
  .rating {
    width: 63.68px;
    height: 25px;
    background: rgba(255, 203, 55, 0.1);
    border: 1px solid #ffcb37;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    img {
      width: 10px;
      height: 9.51px;
      margin-top: 6px;
    }
    div {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #ffcb37;
      margin-top: 3px;
    }
  }
}

.descriptionCourse {
  color: #fff !important;
  padding: 20px;
  width: 100%;
  height: 60px;
  overflow: hidden;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.classtesteimg {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}

.classtesteimgmobile {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}

.blockModeModule {
  z-index: 9;
  position: absolute;
  height: 44px !important;
  width: inherit !important;
}

.dataLiberacaoModule {
  z-index: 9;
  position: absolute;
  width: inherit !important;
  font-size: 13px;
  color: #81858e;
  font-weight: 600;
  white-space: nowrap;
  margin-left: 45px;
  margin-top: 10px;
}
</style>
