<template>
  <span>
    <div
      class="listagem-cursos"
      :class="{ 'courses-mobile': isMobile }"
      id="step2"
      v-if="!order"
    >
      <span v-if="!withoutCategory" :class="{ wd100hg100: isMobile }">
        <div
          data-anima="bottom"
          class="category"
          v-for="categoria in AllHome"
          :key="categoria.id"
        >
          <b-row v-if="hideCourses === 'on' && categoria.courses.length === 0">
            <!-- <div class="flex">
              <p class="titleCategoria">{{ categoria.title }}</p>
            </div> -->
          </b-row>
          <b-row v-else-if="hideCourses === 'off'">
            <div class="flex">
              <p class="titleCategoria">{{ categoria.title }}</p>
            </div>
          </b-row>
          <b-row v-else>
            <div class="flex">
              <p class="titleCategoria">{{ categoria.title }}</p>
            </div>
          </b-row>
          <b-row
            class="d-flex spaceCourse yy"
            v-if="!homeCarousel && !isMobile"
          >
            <b-col
              cols="12"
              v-for="course in categoria.courses"
              :key="course.id"
              :class="{ 'col-md-3': homeVertical, 'col-md-4': !homeVertical }"
            >
              <CourseTemplate
                :withoutCategory="withoutCategory"
                :isMobile="isMobile"
                :course="course"
                :permission="permission"
                :ratingHome="ratingHome"
                :type="'course'"
                :listType="homeVertical"
              ></CourseTemplate>
            </b-col>
          </b-row>
          <b-row
            class="d-flex"
            :class="{
              spaceCourseCarousel: !isMobile,
              spaceCourseCarouselMobile: isMobile,
            }"
            v-else
          >
            <carousel
              v-if="!isMobile"
              :navigation-next-label="nextLabel"
              :navigation-prev-label="prevLabel"
              :mouseDrag="true"
              :loop="true"
              :paginationEnabled="false"
              :adjustableHeight="false"
              :per-page="3"
              :navigationEnabled="true"
            >
              <slide v-for="course in categoria.courses" :key="course.id">
                <CourseTemplate
                  :withoutCategory="withoutCategory"
                  :isMobile="isMobile"
                  :course="course"
                  :permission="permission"
                  :ratingHome="ratingHome"
                  :type="'course'"
                  :listType="homeVertical"
                ></CourseTemplate>
              </slide>
            </carousel>
            <carousel
              v-else
              :mouseDrag="true"
              :loop="true"
              :paginationEnabled="false"
              :per-page="1.5"
            >
              <slide v-for="course in categoria.courses" :key="course.id">
                <CourseTemplate
                  :withoutCategory="withoutCategory"
                  :isMobile="isMobile"
                  :course="course"
                  :permission="permission"
                  :ratingHome="ratingHome"
                  :type="'course'"
                  :listType="homeVertical"
                ></CourseTemplate>
              </slide>
            </carousel>
          </b-row>
        </div>
      </span>
      <span v-if="withoutCategory">
        <div
          data-anima="bottom"
          class="category xx"
          v-for="cursos in AllHome"
          :key="cursos.id"
        >
          <b-row>
            <div class="flex xx">
              <router-link
                v-if="!isMobile"
                :to="'curso/' + cursos.id"
                class="removeLink linkTitle"
              >
                <p class="titleCategoria">{{ cursos.title }}</p>
              </router-link>
              <div class="spaceRatingAdmModule" v-if="ratingHome">
                <div class="rating">
                  <img src="@/assets/icons/star.svg" />
                  <div v-if="cursos.rating !== null">{{ cursos.rating }}</div>
                  <div v-else>0</div>
                </div>
              </div>
              <div class="avaliationModule">
                <div class="qtd-alunos" v-if="permission">
                  {{ cursos.students_count }} {{ $t('home2.course_list.alunos') }}
                </div>
                <div class="qtd-alunos" v-if="permission === false">
                  {{ cursos.porcentagem }} %
                </div>
              </div>
              <div class="actionModuleMode">
                <!-- <router-link v-if="!isMobile" :to="'curso/'+cursos.id" class="removeLink">
                                <img src="@/assets/icons/view2.svg" />
                                Visualizar
                            </router-link> -->
                <router-link
                  v-if="permission && !isMobile"
                  :to="'config_curso/' + cursos.id"
                  class="removeLink"
                >
                  <img src="@/assets/icons/edit2.svg" />
                  Editar
                </router-link>
              </div>
            </div>
          </b-row>
          <b-row class="d-flex spaceCourse" v-if="!homeCarousel && !isMobile">
            <b-col
              cols="12"
              v-for="course in cursos.modules"
              :key="course.id"
              :class="{ 'col-md-3': homeVertical, 'col-md-4': !homeVertical, 'removeModule':!course.should_show }"
            >
              <CourseTemplate
                :AllHome="cursos"
                :withoutCategory="withoutCategory"
                :isMobile="isMobile"
                :course="course"
                :permission="permission"
                :ratingHome="ratingHome"
                :type="'module'"
                :listType="homeVertical"
              ></CourseTemplate>
            </b-col>
          </b-row>
          <b-row
            class="d-flex"
            v-else
            :class="{
              spaceCourseCarouselMobile: isMobile,
              paddingWithout: !isMobile,
            }"
          >
            <carousel
              :navigation-next-label="nextLabel"
              :navigation-prev-label="prevLabel"
              :mouseDrag="true"
              :loop="true"
              :paginationEnabled="false"
              :per-page="isMobile ? 1.2 : 3"
              :navigationEnabled="true"
            >
              <slide v-for="course in cursos.modules" :key="course.id" :class="{'removeModule':!course.should_show}">
                <CourseTemplate
                  :AllHome="cursos"
                  :withoutCategory="withoutCategory"
                  :isMobile="isMobile"
                  :course="course"
                  :permission="permission"
                  :ratingHome="ratingHome"
                  :type="'module'"
                  :listType="homeVertical"
                ></CourseTemplate>
              </slide>
            </carousel>
          </b-row>
        </div>
      </span>
    </div>
    <!-- order for true -->
    <div
      class="listagem-cursos"
      :class="{ 'courses-mobile': isMobile }"
      id="step2"
      v-if="order"
    >
      <span v-if="!withoutCategory" :class="{ wd100hg100: isMobile }">
        <div data-anima="bottom" class="category"
          v-for="categoria in AllHome"
          :key="categoria.id"
        >
          <div>
            <div class="flex">
              <p class="titleCategoria">{{ categoria.title }}</p>
            </div>
          </div>
          <draggable
            tag="b-row"
            :list="categoria.courses"
            :disabled="!enabled"
            class="d-flex spaceCourse classToDrag"
            handle=".itemToDrag"
            @end="onEnd"
          >
            <b-col
              cols="12"
              v-for="course in categoria.courses"
              :key="course.id"
              :class="{ 'col-md-3': homeVertical, 'col-md-4': !homeVertical }"
              class="itemToDrag"
              :data-id="course.id"
            >
              <CourseTemplate
                :withoutCategory="withoutCategory"
                :isMobile="isMobile"
                :course="course"
                :permission="permission"
                :ratingHome="ratingHome"
                :type="'course'"
                :listType="homeVertical"
              ></CourseTemplate>
            </b-col>
          </draggable>
        </div>
      </span>
    </div>
  </span>
</template>

<script>
import CourseTemplate from "@/components/home/CourseTemplate.vue";
import { Carousel, Slide } from "vue-carousel";
import draggable from "vuedraggable";
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();
import notify from "@/services/libs/notificacao";
import { mapActions } from "vuex";

export default {
  props: [
    "AllHome",
    "isMobile",
    "permission",
    "withoutCategory",
    "hideCourses",
  ],
  components: {
    CourseTemplate,
    Carousel,
    Slide,
    draggable,
  },
  data() {
    return {
      ratingHome: true,
      homeVertical: true,
      homeCarousel: false,
      nextLabel:
        '<div class="arrow right-arrow"><i class="fa fa-angle-right"></i></div>',
      prevLabel:
        '<div class="arrow left-arrow"><i class="fa fa-angle-left"></i></div>',
      order: false,
      enabled: true,
      dragging: false,
    };
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
  },
  methods: {
    onEnd: function (e) {
      //console.log("event", e);
      setTimeout(() => {
        var array = [];
        var lessons = document.querySelectorAll(".itemToDrag");
        for (let i = 0; i < lessons.length; i++) {
          const lessonId = lessons[i].getAttribute("data-id");
          array.push(parseInt(lessonId));
        }
        //console.log("xxxxx", array);
        let data = {
          id: "/order",
          array,
        };
        this.$root.$emit("loadOn");
        serviceCourse
          .postIDArray(data)
          .then((resp) => {
            //console.log("order course", resp, array);
            notify("sucesso", "Ordenação Salva!");
            this.$root.$emit("loadOff");
          })
          .catch((err) => {
            //console.log(err);
            notify("erro", "Erro ao Salvar Ordenação!");
            this.$root.$emit("loadOff");
          });
      }, 1000);
    },
    async getOptions() {
      let resp = await this.$store.dispatch("fetchMetas", [
        "rating_home",
        "home_carousel",
        "home_vertical"
      ])
      if (resp.rating_home === null || resp.rating_home === "on") {
        this.ratingHome = true;
      } else {
        this.ratingHome = false;
      }

      if (resp.home_carousel === "off" || resp.home_carousel === null) {
        this.homeCarousel = false;
      } else {
        this.homeCarousel = true;
      }

      if (resp.home_vertical === "off" || resp.home_vertical === null) {
        this.homeVertical = false;
      } else {
        this.homeVertical = true;
      }
    },
  },
  mounted() {
    this.getOptions();
    this.$root.$on("orderOn", (data) => {
      //console.log(data);
      this.order = true;
    });
    this.$root.$on("orderOff", (data) => {
      //console.log(data);
      this.order = false;
    });
  },
};
</script>

<style lang="scss">
.paddingWithout {
  margin-right: 7px !important;
  margin-left: 14px !important;
}

.removeModule {
    display: none !important;
}

.classToDrag {
  display: contents;
  div {
    cursor: grab;
  }
}
.listagem-cursos {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: -25px;

  .flex {
    display: flex;
  }
  .titleCategoria {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: var(--fontcolor);
  }
  .titleCategoria::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid var(--maincolor);
    background: var(--maincolortrans);
    display: block;
    margin-right: 20px;
    margin-left: 15px;
  }

  .row.d-flex.spaceCourseCarousel {
    padding: 0px 23px 0px 60px;
    width: 100%;
  }
  .row.d-flex.spaceCourseCarouselMobile {
    width: 100%;
  }
}
.listagem-cursos.courses-mobile {
  margin-top: 10px;
}

.spaceRatingAdmModule {
  width: 80px;
  height: 65px;
  display: flex;
  padding-top: 13px;
  z-index: 9;
  margin-left: 10px;
  .rating {
    width: 63.68px;
    height: 25px;
    background: rgba(255, 203, 55, 0.1);
    border: 1px solid #ffcb37;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    img {
      width: 10px;
      height: 9.51px;
      margin-top: 6px;
    }
    div {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #ffcb37;
      margin-top: 3px;
    }
  }
}

.avaliationModule {
  .qtd-alunos {
    font-size: 13px;
    color: #81858e;
    font-weight: 600;
    white-space: nowrap;
    margin-top: 16px;
  }
}

.actionModuleMode {
  display: flex;
  gap: 10px;
  margin-left: 15px;
  margin-top: -11px;
}

.actionModuleMode a:first-child {
  margin-right: 30px;
}
.actionModuleMode a {
  display: flex;
  align-items: center;
  color: var(--fontcolor);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}
.actionModuleMode a img {
  margin-right: 10px;
  filter: var(--filtericon);
}

button.VueCarousel-navigation-button {
  height: 100% !important;
  margin-top: -8px !important;
}

.VueCarousel-navigation-button:focus {
  outline: 1px solid rgba(0, 0, 0, 0) !important;
}

.VueCarousel {
  width: 100%;
}

.arrow {
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: all 0.25s ease;
  top: -25px;
  position: relative;
  .fa-angle-right {
    padding-right: 10px;
    padding-left: 5px;
    color: #d4d4d4;
  }
  .fa-angle-left {
    padding-left: 10px;
    padding-right: 5px;
    color: #d4d4d4;
  }
}

.spaceCourse:hover .arrow {
  opacity: 1;
}

.removeLink {
  text-decoration: none;
  cursor: pointer;
}

.linkTitle {
  margin-top: 10px;
}
</style>